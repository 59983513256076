import React from "react";
import Layout from "../components/Layout";
import styled from "styled-components";
import tw from "tailwind.macro";
import Terms from "../components/Legal/terms";

const Container = styled.div`
  ${tw` my-32`}
`


const TermsAndConditions = () => {

  return <Layout>
    <Container>
      <Terms />
    </Container>
  </Layout>

}

export default TermsAndConditions